@keyframes slideIn {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(4px);
    }
}

.footer {
    width: 100%;
    background-color: ghostwhite;
    flex-shrink: 0;
    margin: 0 auto;
    bottom: 0;
    position: fixed;
}

.footer__container {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.footer__organization-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.footer__organization-text {
    font-size: 12px;
    font-weight: 500;
    max-width: 360px;
    margin: 0;
}

.footer__organization-text_red {
    color: red;
    font-size: 12px;
}

.footer__adres-container {
    display: flex;
    flex-direction: column;
    max-width: 375px;
}

.footer__organization-adres {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.footer__link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.footer__link-list {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer__link {
    color: black;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 5px;
}

.footer__link:hover {
    opacity: 0.7;
}

.footer__icon {
    width: 45px;
    height: 45px;
}

.footer__icon:hover {
    animation: slideIn 1s ease-in-out infinite;
}


.footer__link-form {
    align-content: end;
}

@media screen and (max-width: 925px) {
    .footer__icon {
        width: 30px;
        height: 30px;
    }

    .footer__adres-container {
        max-width: 300px;
    }

    .footer__organization {
        max-width: 300px;
    }

    .footer__container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 730px) {
    .footer__icon {
        width: 25px;
        height: 25px;
    }

    .footer__adres-container {
        max-width: 250px;
    }

    .footer__organization {
        max-width: 250px;
    }

    .footer__container {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 700px) {
    .footer {
        width: 720px;
        min-width: 720px;
    }

    .footer__container {
        padding-left: 20px;
        padding-right: 20px;
    }
}