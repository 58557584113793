.ringtimes {
    width: 100%;
    flex: 1;
}

.ringtimes__container {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    margin: 0 auto;
    align-items: center;
}

.ringtimes__title {
    margin-bottom: 60px;
    font-size: 30px;
}