.burger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: right 0.3s ease-in-out;
    z-index: 1;
    font-family: 'Inter', Arial, sans-serif;
}

.burger__container {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 375px;
    min-width: 320px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 1;
}

.burger__button_close {
    border: none;
    background: none;
    background-image: url('../image/closeBtn__Burger.svg');
    width: 32px;
    height: 32px;
    position: absolute;
    top: 29px;
    right: 29px;
    padding: 0;
    cursor: pointer;
}


.burger__menu {
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    gap: 28px;

}

.burger__menu_link {
    color: blue;
    text-align: center;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.burger__menu_link:hover {
    opacity: 0.7;
}

.burger__menu-link-active {
    padding-bottom: 4px;
    border-bottom: 2px solid #000;
    transition: 0.3s ease;
}

.burger__menu_link-down {
    margin-bottom: 80px;
}




@media screen and (max-width: 400px) {
    .burger__menu {
        margin-top: 144px;
        gap: 23px;
    }

    .burger__button_profile {
        margin-bottom: 110px;
    }

    .burger__button_close {
        top: 13px;
        right: 18px;
    }
}