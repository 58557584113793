.header {
    width: 100%;
    background-color: ghostwhite;
    margin: 0 auto;
  }
  
  .header__container {
    max-width: 1280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px; 
    flex-direction: row;
    margin: 0 auto;
    padding: 10px; 
  }
  
  .header__img {
    width: 120px; 
    height: 120px; 
    border-radius: 50%;
    background-clip: content-box;
    align-self: flex-start;
    cursor: pointer;
  }
  
  .header__title {
    font-size: 28px; 
    margin: 0 55px 0 0;
    font-weight: 500;
    color: rgb(49, 158, 194);
  }
  
  .header__button-burger {
    background: none;
    background-image: url('../../image/Burger__icon.svg');
    width: 40px; 
    height: 40px;
    border: none;
    cursor: pointer;
    transition: 0.3s linear;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .header__button-burger:hover {
    opacity: 0.8; 
  }
  
  .header__link:hover {
    opacity: 0.8; 
  }
  
  @media screen and (max-width: 925px) {
    .header__title {
      font-size: 22px; 
    }
  }
  
  @media screen and (max-width: 730px) {
    .header__title {
      font-size: 16px; 
    }
  
    .header__img {
      width: 60px; 
      height: 60px; 
    }
  
    .header__button-burger {
      width: 20px; 
      height: 20px; 
    }
  }
  
  @media screen and (max-width: 700px) {
    .header {
      width: 720px;
      min-width: 720px;
    }
  }