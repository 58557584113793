.save-xlsx__btn {
    border-radius: 8px;
    background: #007bff;
    cursor: pointer;
    width: 280px;
    min-height: 45px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    transition: opacity .2s ease;
    align-self: center;
}

.save-xlsx__btn:hover {
    opacity: 0.6;
}

.save-xlsx__btn:active {
    transform: scale(0.95);
}