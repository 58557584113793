@keyframes list {
    0%, 100% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(10px);
    }
  }

.aboutgroups {
    flex: 1;
    position: relative;
    margin-bottom: 150px;
}

.aboutgroups__title {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 54px;
}

.aboutgroups__list {
    list-style-type: none;
    padding: 0;
    list-style: none;
}

.aboutgroups__list-element {
    list-style: none;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.aboutgroups__list-element:hover {
    background-color: lightblue;
    animation: list 1s ease-in-out;
}


.aboutgroups__image {
    max-width: 650px;
}

.aboutgroups__image:hover {
    opacity: 0.6;
}


@media screen and (max-width: 768px) {
    .aboutgroups__title {
     font-size: 44px;
    }
    .aboutgroups__image {
        max-width: 500px;
    }
  }

  @media screen and (max-width: 375px) {
    .aboutgroups__title {
     font-size: 24px;
    }
    .aboutgroups__image {
        max-width: 300px;
    }
    .aboutgroups {
        width: 375px;
        margin: 0 auto;
    }
  }