@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.custom-modal_text-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
}

.custom-modal_title {
    margin: 0;
    font-size: 16px;
}

.custom-modal__text {
    margin: 0;
    text-align: center;
}

.custom-modal__loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-modal__loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}