.rasp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  margin-bottom: 150px;
}

.rasp__container {
  min-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.rasp__text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
  font-weight: 500;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.rasp__link {
cursor: pointer;
color: black;
font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.rasp__title {
  margin: 0 auto;
}

.table-container {
  font-family: Arial, sans-serif;
}

.rasp__table-icon-container {
  display: flex;
  flex-direction: row;
  gap:2px;
  align-items: center;
  justify-content: flex-end;
}

.rasp__table-icon {
width: 25px;
height: 25px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
}


tr:nth-child(even) {
  background-color: #f9f9f9;
}


tr:nth-child(odd) {
  background-color: #ffffff;
}

tr:hover {
  background-color: lightblue;
}


/* Стили для пагинации */
.pagination {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
}

.pagination li.active {
  font-weight: bold;
}

.rasp__not-found-text {
  margin: 0;
  color:red !important;
}

@media screen and (max-width: 1300px) {
  .rasp__container {
    min-width: 980px;
  }
  table {
    width: 90%;
  }
}

@media screen and (max-width: 1000px) {
  .rasp__container {
    min-width: 820px;
  }
}

@media screen and (max-width: 820px) {
  .rasp__container {
    min-width: 720px;
  }
  table th td {
    font-size: 12px;
  }
}

@media screen and (max-width: 820px) {
  .rasp__table-icon {
    width: 15px;
    height: 15px;
    }
}

