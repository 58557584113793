body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('./styles/vendor/normalize.css');
@import url('./styles/Rasp.css');
@import url('./styles/App.css');
@import url('./components/Header/Header.css');
@import url('./styles/Burger.css');
@import url('./styles/RingTimes.css');
@import url('./styles/AboutGrups.css');
@import url('./styles/footer.css');
@import url('./styles/modal.css');
@import url('./components/SaveXlsx/savexslx.css');
@import url('./styles/Search.css');
@import url('./styles/contactForm.css');