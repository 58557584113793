.custom-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 0s, opacity 0.5s linear;
}

.custom-modal-content {
    position: relative;
    max-width: 600px;
    min-height: 450px;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 34px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 37px;
    background-color: #fff;
    box-shadow: 0 0 25px rgb(0, 0, 0, 0.15);
    border-radius: 10px;
    box-sizing: border-box;
}

.custom-modal__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    gap: 12px;
}

.custom-modal-close {
    position: absolute;
    top: -38px;
    right: -38px;
    min-width: 32px;
    min-height: 32px;
    background-image: url('../image/Close_icon.svg');
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: opacity .2s ease;
    border: 0;
    cursor: pointer;
}

.custom-modal__textarea {
    padding: 10px;
    font-size: 16px;
    border: 1px solid black;
    width: 465px;
    min-height: 150px;
    resize: vertical;
    border-radius: 12px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.custom-modal__textarea:focus {
    border-color: #007bff;
    outline: none;
}

.custom-modal__submit-btn {
    border-radius: 5px;
    background: #007bff;
    cursor: pointer;
    width: 300px;
    min-height: 45px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: 0;
    transition: opacity .2s ease;
    align-self: center;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    margin-top: 10px;
}

.custom-modal__submit-btn:hover {
    opacity: 0.9;
}

.custom-modal__submit-btn:active {
    transform: scale(0.95);
}

.custom-modal__input-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.custom-modal__label {
    font-size: 16px;
    font-weight: 500;
}

.custom-modal__input {
    padding: 10px;
    font-size: 16px;
    border-radius: 12px;
    border: 1px solid black;
    width: 485px;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    background-color: #FFF;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.custom-modal__input:focus {
    border-color: #007bff;
    outline: none;
}

.custom-modal__checkbox {
    width: 18px;
    height: 18px;
    margin: 0;
}

.custom-modal__checkbox-container {
    margin: 5px 0 10px;
    display: flex;
    flex-direction: row;
    max-width: 450px;
    justify-content: center;
}

.custom-modal__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-modal__checkbox+label {
    display: inline-flex;
    user-select: none;
}

.custom-modal__checkbox+label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid black;
    border-radius: 5px;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

.custom-modal__checkbox:checked+label::before {
    content: '✓';
    border-color: #272A31;
    background-color: white;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.custom-modal__checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: #b8b3ff;
}

.custom-modal__checkbox:not(:disabled):active+label::before {
    background-color: #b8b3ff;
    border-color: #b8b3ff;
}

.custom-modal__checkbox:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-modal__checkbox:focus:not(:checked)+label::before {
    border-color: #80bdff;
}

.custom-modal__checkbox:disabled+label::before {
    background-color: #e9ecef;
}

.custom-modal-close:hover,
.custom-modal-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.custom-modal__checkbox-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-modal__error-text {
    color: #FF014E;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 465px;
    align-self: flex-start;
    position: relative;
}

.custom-info-modal {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-info-modal-content {
    position: relative;
    max-width: 300px;
    min-height: 200px;
    padding-top: 34px;
    padding-left: 36px;
    padding-right: 36px;
    padding-bottom: 37px;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
}

.custom-info-modal-text {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}