.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

@media screen and (max-width: 540px) {
    .App {
        width: 720px;
    }
}

@media screen and (max-width: 390px) {
    .App {
        width: 720px;
    }
}

@media screen and (max-width: 320px) {
    .App {
        width: 720px;
    }
}